<template>
	<router-view></router-view>
</template>

<script type="text/javascript">

	export default {
		name: 'Monte',
	}

</script>